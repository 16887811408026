/* BannerList.css */

.banner-list-container {
    overflow: hidden;
    position: relative;
    /* width: 600px;  */
    margin: 0 auto;
    width: 100%;
    min-width: 100%;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    list-style-position: outside;
    margin-left: 0px;
    margin-right: 0px;
    scroll-behavior: smooth;

  }
  
  .banner-list {
    transition-property: transform;
  }
  
  .banner-item {
    margin-right: 10px;
    transition: ease 1000ms;
  }
  
  .banner-image {
    height: 148px;
    width: 280px;
    border-radius: 5%;
  }
  