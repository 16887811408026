.order-status {
    font-weight: bold;
  }
  
  .order-status.requested {
    color: blue; /* or any other style you prefer for the requested status */
  }
  
  .order-status.accepted {
    color: green; /* or any other style you prefer for the accepted status */
  }
  
  .order-status.rejected {
    color: red; /* or any other style you prefer for the rejected status */
  }
  
  .order-status.completed {
    color: purple; /* or any other style you prefer for the completed status */
  }



/* Add your custom styles for clicked button */
