.main-page {
    background-color: white;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 10px;
}

.side-margin {
    margin-right: '20px';
    margin-left: '20px';
}
