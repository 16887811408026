.product {
    display: flex;
    align-items: flex-start; /* Align items to the top */
  }
  
  .product-image {
    width: 150px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 20px; /* Add some spacing between image and details */
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
  }
  
  .product-name {
    margin-bottom: 10px; /* Add space between name and description */
  }
  
  .product-description {
    margin-bottom: 10px; /* Add space between description and price */
  }
  
  .product-price {
    margin-top: auto; /* Push the price to the bottom */
    font-weight: bold;
    margin-bottom: 20px;
  }

  /* for scroll */
  .scrollable-container {
    /* width: 300px;
    height: 200px; */
    overflow: auto;
    border: 1px solid #ccc;
    white-space: nowrap; /* Prevent text wrapping */
    cursor: grab;
  }
  
  .content {
    display: inline-block;
    white-space: nowrap;
    background-color: #f0f0f0;
  }
  /* end for scroll */


/* order path label */
  .order-path {
    width: '50%';
  }
  /* main color shop */
  .color-green {
    color: #368B1C;
  }

  .background-color-green {
    background-color: #368B1C;
  }

  .main-background {
    display: block;
    width: 100%;
    max-width: 100%;
    overflow-x:hidden;
    background-color: white;
  }