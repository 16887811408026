.category {
    height: 50px;
    min-width: 60px;
    cursor: pointer;
}


.color-green {
  color: #368B1C;
}

* {

}

.color-green-background {
  background-color: #368B1C;
}

.color-orange {
  color: orange;
}

.product-description {
  font-weight: bold;
}

.plus-minus {
  color: #368B1C;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 3px;
  height: 35px;
  width: 35px;
  margin-right: 3px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap: 16px; /* Optional: Space between the items */
}

.product {
  display: block;
  width: 100%;
}

.product-image {
  width: 100%;
  aspect-ratio: 1/1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.product-detail-text {
  display: grid;
  height: 350px;
}

.product-detail {
  display: grid;
}

.product-count-row {
  align-self: end;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .product {
    width: 100%;
    
  }

  .product-detail-text{
    height: 200px;
  }
  
  /* .product-image {
    width: 50%;
    height: 50%;
  } */
}